import React, { useContext } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import MenuContext from '../../context/menu-context';
import * as styles from './RestaurantTranslations.module.scss';

function RestaurantTranslations(props) {
  const { restaurant } = props;
  const { translations } = restaurant;
  const ctx = useContext(MenuContext);
  const {
    languages,
    switchLanguage
  } = ctx;

  const getRestaurantLanguageLink = (language) => {
    // If Restaurant object is from current language
    if (language.id === restaurant.language.id) {
      return <span className={styles[language.code.toLowerCase()]}>{language.name}</span>;
    }

    // Get matching translation for language
    const langTranslation = translations.find((el) => el.language.id === language.id);
    if (langTranslation) {
      return (
        <Link
          className={styles[language.code.toLowerCase()]}
          to={`/${langTranslation.language.code.toLowerCase()}/${langTranslation.slug}`}
          onClick={() => {
            switchLanguage(langTranslation.language.code.toLowerCase())
          }}
        >
          {language.name}
        </Link>
      );
    }

    // If no translation is found for restaurant
    // redirect to home page of langauge
    return (
      <Link
        to={language.homeUrl}
        className={styles[language.code.toLowerCase()]}
        onClick={() => {
          switchLanguage(langTranslation.language.code.toLowerCase())
        }}
      >
        {language.name}
      </Link>
    );
  };

  const renderLangSwitcher = () => {
    if (languages.length && translations.length) {
      return (
        <ul className={styles.languageSwitcher}>
          {languages.map((item) => (
            <li key={item.code}>
              {getRestaurantLanguageLink(item)}
            </li>
          ))}
        </ul>
      );
    }
    return null;
  };

  return renderLangSwitcher();
}

RestaurantTranslations.propTypes = {
  restaurant: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    language: PropTypes.shape({
      id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }).isRequired,
    translations: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      language: PropTypes.shape({
        id: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      }).isRequired,
    })).isRequired,
  }).isRequired,
};
export default RestaurantTranslations;
