import React from "react";
import PropTypes from "prop-types";

import * as styles from "./RestaurantDetails.module.scss";
import PigHollow from "../../components/elements/icons/PigHollow";
import RestaurantMenuButtons from "./RestaurantMenuButtons";
import RestaurantTranslations from "./RestaurantTranslations";
import RestaurantBookingButton from "./RestaurantBookingButton";
function RestaurantDetails(props) {
  const { restaurant, menus } = props;
  const {
    name: restaurantName,
    restaurantDetails: { description, contactDetails },
  } = restaurant;

  return (
    <div className={styles.restaurantDetailsOuterWrap}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className={styles.restaurantDetailsInnerWrap}>
              <div className={styles.iconWrap}>
                <PigHollow />
              </div>
              <div className={styles.titleWrap}>
                <h2>{restaurantName}</h2>
              </div>
              <div
                className={styles.descriptionWrap}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: description }}
              />
              <RestaurantTranslations restaurant={restaurant} />
              <RestaurantMenuButtons menus={menus} />
              <div
                className={styles.footerDescriptionWrap}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: contactDetails }}
              />
              <RestaurantBookingButton restaurant={restaurant} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

RestaurantDetails.propTypes = {
  restaurant: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    restaurantDetails: PropTypes.shape({
      description: PropTypes.string,
      contactDetails: PropTypes.string,
    }).isRequired,
    translations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  menus: PropTypes.shape({
    nodes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};
export default RestaurantDetails;
