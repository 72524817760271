import contactUsEn from "../assets/images/popup/contact-us-en.jpg";
import contactUsEs from "../assets/images/popup/contact-us-es.jpg";

/**
 * Filter out menu buttons for restaurant
 * @param menus | object with nodes i.e. array of menus
 * @param restaurant | restaurant object
 * @return menus | return in same format after filtering array
 */
export function getFilteredMenusList(menus, restaurant) {
  if (restaurant.slug === "la-laguna" || restaurant.slug === "santa-cruz") {
    const { nodes: menuItems } = menus;
    const filteredMenus = menuItems.filter((item) => {
      return item.slug !== "menu-del-medio-dia";
    });
    return { nodes: filteredMenus };
  }
  return menus;
}

/**
 * Add booking button after restaurant content
 * @param restaurant | restaurant object
 * @return string | URL for iframe
 */
export function getRestaurantBookingURL(restaurant) {
  if (restaurant?.slug === "albacete") {
    // return "https://www.covermanager.com/reservation/module_restaurant/restaurante-mad-ramen/english";
    return "https://widget.thefork.com/es/7f70e350-5418-4a59-8a1a-eccece403d7e";
  }
  return false;
}

/**
 * Get Contact US Page popup media
 * @param langCode | current language code
 * @return object | {media: mediaUrl, mediaType: "image" or "video"}
 */
export function getContactUsPopupMedia(langCode) {
  if (langCode === "en") {
    return { media: contactUsEn, mediaType: "image" };
  }
  return { media: contactUsEs, mediaType: "image" };
}
