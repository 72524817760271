import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'gatsby';
import * as styles from './RestaurantMenuButtons.module.scss';

function RestaurantMenuButtons(props) {
  const {
    menus: {
      nodes: menuItems,
    },
  } = props;
  return (
    <div className={styles.menuButtonsWrap}>
      {menuItems.map((menu) => (
        <Link key={menu.id} to={menu.slug}>
          {menu.name}
        </Link>
      ))}
    </div>
  );
}

RestaurantMenuButtons.propTypes = {
  menus: PropTypes.shape({
    nodes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};
export default RestaurantMenuButtons;
