import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Seo from "gatsby-plugin-wpgraphql-seo";
import MenuBar from "../partials/global/MenuBar";
import BackgroundImage from "../components/elements/BackgroundImage";
import RestaurantDetails from "../partials/restaurant/RestaurantDetails";
import RestaurantPopup from "../partials/restaurant/RestaurantPopup";
import { getFilteredMenusList } from "../utilities/functions";
function Restaurant(props) {
  const {
    data: { restaurant, placeholderImage, restaurantMenus },
  } = props;

  const menus = getFilteredMenusList(restaurantMenus, restaurant);
  const {
    slug,
    restaurantDetails: { backgroundImage, popupMedia },
  } = restaurant;

  const { localFile: restaurantImage, altText } = backgroundImage || {
    localFile: placeholderImage,
    altText: "Restaurant Image",
  };

  return (
    <section className="full-height-section">
      <Seo post={restaurant} />
      <MenuBar />
      <RestaurantPopup slug={slug} popup={popupMedia} />
      <div className="overflow-hidden flex-grow-1">
        <BackgroundImage image={restaurantImage} alt={altText} />
        <RestaurantDetails restaurant={restaurant} menus={menus} />
      </div>
    </section>
  );
}

export const query = graphql`
  query ($restaurantId: String, $languageCode: WpLanguageCodeEnum) {
    restaurant: wpRestaurant(id: { eq: $restaurantId }) {
      seo {
        ...YoastTaxonomySEOContent
      }
      id
      slug
      name
      language {
        id
        slug
        code
      }
      translations {
        id
        name
        slug
        language {
          id
          slug
          code
        }
      }
      restaurantDetails {
        cocktails
        terraza
        wheelchair
        wifi
        featuredImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1)
            }
          }
        }
        backgroundImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        popupMedia
        description
        contactDetails
      }
    }
    restaurantMenus: allWpRestaurantMenu(
      filter: { language: { code: { eq: $languageCode } } }
      sort: { order: ASC, fields: termOrder }
    ) {
      nodes {
        id
        slug
        name
      }
    }
    placeholderImage: file(
      relativePath: { eq: "placeholders/restaurant-bg.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

Restaurant.propTypes = {
  data: PropTypes.shape({
    restaurant: PropTypes.shape({
      restaurantDetails: PropTypes.shape({
        backgroundImage: PropTypes.shape({
          localFile: PropTypes.shape({}).isRequired,
          altText: PropTypes.string.isRequired,
        }),
        popupMedia: PropTypes.string,
      }).isRequired,
    }).isRequired,
    placeholderImage: PropTypes.shape({}).isRequired,
    restaurantMenus: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }).isRequired
      ),
    }).isRequired,
  }).isRequired,
};

export default Restaurant;
